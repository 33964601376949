import { useQuery } from "@apollo/client";
import React from "react";
import BlogOverview from "../components/organisms/BlogOverview/BlogOverview";
import { GET_BLOGS } from "../g/queries/get-blogs";
import { PageTitle, SEO } from "../modules/seo";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: {
    key: string;
  };
}

const Blog = ({ location }: { location: ILocation }) => {
  const { data } = useQuery(GET_BLOGS, {
    variables: { query: "news", number: 6 },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.Blog} />
      <BlogOverview
        blogData={data?.blogs?.edges[0]?.node}
        hash={location?.hash}
      />
    </div>
  );
};

export default Blog;
