/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import classNames from "classnames";
import { cards } from "../../../constants/askUsCard";
import * as s from "./BlogOverview.module.scss";
import Article from "../../atoms/Article/Article";
import AskInput from "../../atoms/AskInput/AskInput";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_BLOGS, GET_BLOGS_PAGINATION } from "../../../g/queries/get-blogs";
import { useEffect } from "react";
import { first, get, replace } from "lodash";

const cn = classNames.bind(s);

interface BlogOverviewProps {
  blogData: Blog;
  hash?: string;
}

interface INews {
  node: IArticle;
  cursor: string;
}

const BlogOverview = ({ blogData, hash }: BlogOverviewProps) => {
  const [selected, setSelected] = useState<string | undefined>("<b>Blog</b>");
  const [component, setComponent] = useState<undefined | JSX.Element>();
  const [cursor, setCursor] = useState<string>("");
  const articles = blogData?.articles?.edges;
  const [allArticles, setAllArticles] = useState<INews[]>([]);
  const [nextPage, setNextPage] = useState(
    blogData?.articles?.pageInfo?.hasNextPage
  );
  const [newNews, setNewNews] = useState<INews[]>(articles);
  const [buttonText, setButtonText] = useState("More articles");

  useQuery(GET_BLOGS, {
    variables: { query: "news", number: 100 },
    onCompleted: (a) => {
      setAllArticles(get(first(get(a, "blogs.edges")), "node.articles.edges"));
    },
  });

  const { data } = useQuery(GET_BLOGS, {
    variables: {
      query: "research&studies",
      number: 7,
    },
  });

  const [getNews] = useLazyQuery(GET_BLOGS_PAGINATION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setNewNews([
        ...blogData?.articles?.edges,
        ...data?.blogs?.edges[0]?.node?.articles?.edges,
      ]);
      setNextPage(data?.blogs?.edges[0]?.node?.articles?.pageInfo?.hasNextPage);
    },
  });

  useEffect(() => {
    setNewNews(articles);
    setNextPage(blogData?.articles?.pageInfo?.hasNextPage);
  }, [articles]);

  useEffect(() => {
    if (hash === "#research") {
      setSelected("Research <b>& studies</b>");
      setComponent(cards[1].component);
    } else if (hash === "#faq") {
      setSelected(cards[2].title);
      setComponent(cards[2].component);
    } else if (hash === "#blog") {
      setSelected(cards[0].title);
      setComponent(cards[0].component);
    } else {
      setSelected(cards[0].title);
      setComponent(cards[0].component);
    }
  }, [hash]);

  const loadMoreArticles = () => {
    if (nextPage) {
      getNews({
        variables: {
          query: "news",
          cursor: cursor,
        },
      });
    } else {
      setButtonText("No more articles");
    }
  };

  useEffect(() => {
    if (newNews && newNews.length)
      setCursor(newNews[newNews.length - 1].cursor || "");
  }, [newNews]);

  const handleChange = (card: IBlogCards) => {
    if (card.title === selected) {
      setSelected(undefined);
      setComponent(undefined);
    } else {
      setSelected(card.title);
      setComponent(card.component);
    }
  };

  const handleSearchChange = (e: React.BaseSyntheticEvent) => {
    let search = e.target.value;
    let search2 = "";
    if (search.includes("'")) {
      search2 = e.target.value; // lion's mane
      search = search.replace("'", ""); // lions mane
    }
    if (search === "") {
      setNewNews(allArticles.slice(0, 6));
    }
    if (search === "lions mane") {
      search = "lion's mane";
    } else {
      const tempArticles =
        allArticles &&
        allArticles.filter((a) => {
          if (search2) {
            return (
              a.node.title.toLowerCase().includes(search2.toLowerCase()) ||
              a.node.content.toLowerCase().includes(search2.toLowerCase()) ||
              a.node.title.toLowerCase().includes(search.toLowerCase()) ||
              a.node.content.toLowerCase().includes(search.toLowerCase())
            );
          }
          return (
            a.node.title.toLowerCase().includes(search.toLowerCase()) ||
            a.node.content.toLowerCase().includes(search.toLowerCase())
          );
        });
      setNewNews(tempArticles);
    }
  };

  return (
    <div className={s.mainContainer}>
      <div className={s.container}>
        <div className={s.askUsWrapper}>
          <p className={s.askUsTitle}>
            TROOP <span>TALK</span>
          </p>
          <p className={s.askUsSubtitle}>
            Everything you want to know about Troop
          </p>
          <AskInput
            placeholder="Search for anything"
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
        <div className={s.cardsWrapper}>
          {cards.map((c) => (
            <div
              onClick={() => handleChange(c)}
              key={c.title}
              className={cn(s.card, {
                [s.cardSelected]: selected === c.title,
              })}
            >
              <p
                className={s.title}
                dangerouslySetInnerHTML={{ __html: c.title }}
              ></p>
            </div>
          ))}
        </div>
      </div>
      {component ? (
        <div className={s.tabsWrapper}>
          {/* {component} */}
          {selected?.includes("Research")
            ? React.cloneElement(component, {
                data: data?.blogs?.edges[0].node,
              })
            : component}
          <div className={s.articles}>
            {(articles?.slice(0, 2) || []).map((a, i) => (
              <Article article={a.node} key={i} />
            ))}
          </div>
        </div>
      ) : (
        <div className={s.articles} id="blog">
          {(newNews || []).map((a, i) => (
            <Article article={a.node} key={i} />
          ))}
        </div>
      )}

      <button onClick={() => loadMoreArticles()}>{buttonText}</button>
    </div>
  );
};

export default BlogOverview;
