// extracted by mini-css-extract-plugin
export var articleText = "Research-module--article-text--Rc3UY";
export var articleTitle = "Research-module--article-title--emOB5";
export var articleWrapper = "Research-module--article-wrapper--aCPxQ";
export var container = "Research-module--container--IXLR-";
export var description = "Research-module--description--R7eAf";
export var imageWrapper = "Research-module--image-wrapper--nJT8v";
export var readMore = "Research-module--read-more--3QofK";
export var readMoreWrap = "Research-module--read-more-wrap--UVkjc";
export var subtitle = "Research-module--subtitle--+PI6A";
export var textWrapper = "Research-module--text-wrapper--iezNT";
export var title = "Research-module--title--xWqrs";
export var wrapper = "Research-module--wrapper--wHmiv";