import QuestionWrapper from "../components/organisms/QuestionWrapper/QuestionWrapper";
import React from "react";
import Research from "../components/molecules/Research/Research";
import Blog from "../images/blogicon.svg";
import ResearchImg from "../images/Group 543.svg";
import Faq from "../images/Group 545.svg";

export const cards = [
  {
    title: "<b>Blog</b>",
    icon: Blog,
    // eslint-disable-next-line react/display-name
  },
  {
    title: "Research <br/><b>& studies</b>",
    icon: ResearchImg,
    // eslint-disable-next-line react/display-name
    component: (props: any) => <Research {...props} />,
  },
  {
    title: "Faq & <br /><b>Support</b>",
    icon: Faq,
    component: () => <QuestionWrapper productsPage={false} />,
  },
];
