/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React from "react";
import * as s from "./Research.module.scss";
import img from "../../../images/boca.svg";
import arrowReadMore from "../../../images/arrowReadMore.svg";
import { navigate } from "gatsby";

const Research = ({ data }: { data: Blog }) => {
  const handleClick = (article: IArticle) => {
    navigate("/blog-single", { state: { article } });
  };

  return (
    <div className={s.container} id="research">
      <div className={s.wrapper}>
        <div className={s.textWrapper}>
          <p className={s.title}>{data?.title}</p>
          <p className={s.description}>
            Keep up with the latest mushroom science and published studies.
          </p>
        </div>
        <div className={s.imageWrapper}>
          <img src={img} alt=""></img>
        </div>
      </div>
      {data?.articles?.edges.map((a: { node: IArticle }, i: number) => (
        <div className={s.articleWrapper} key={i}>
          <p className={s.articleTitle}>{a.node.title}</p>
          <p className={s.articleText}>{a.node.content}</p>
          <div className={s.readMoreWrap}>
            <p
              className={s.readMore}
              onClick={() => handleClick(a?.node)}
            >{`Read full article`}</p>
            <img src={arrowReadMore} alt="read-full" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Research;
