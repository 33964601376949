// extracted by mini-css-extract-plugin
export var articles = "BlogOverview-module--articles--XP7Jd";
export var askUsSubtitle = "BlogOverview-module--ask-us-subtitle--2TpM6";
export var askUsTitle = "BlogOverview-module--ask-us-title--2kD+6";
export var askUsWrapper = "BlogOverview-module--ask-us-wrapper--pYjch";
export var card = "BlogOverview-module--card--repuO";
export var cardSelected = "BlogOverview-module--card-selected--Y9jkf";
export var cardsWrapper = "BlogOverview-module--cards-wrapper--TkW5S";
export var container = "BlogOverview-module--container--fBpO9";
export var mainContainer = "BlogOverview-module--main-container--voiZo";
export var tabsWrapper = "BlogOverview-module--tabs-wrapper--m6E-l";
export var title = "BlogOverview-module--title--1BuTb";